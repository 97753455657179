// JavaScript Document
'use strict';
var baseUrl = APP_URL + '/';
var flashstatus = $('span.flashstatus').text();
var flashmessage = $('span.flashmessage').text();
var pagetype = jQuery('input[name="pagetype"]').val();

/**Right click prevent */
document.addEventListener('contextmenu', function(event) {
	// Prevent the default right-click behavior
	event.preventDefault();
});
/**Right click prevent */

$(document).ready(function (e) {
	if ($.isFunction($.fn.tooltip)) {
		$('[data-toggle="tooltip"]').tooltip()
	}
	/* ACCORDION */
	(function () {
		'use strict';
		//  Faqs Accordion
		var faqsAccordion = function () {
			var faqAcc = $('.faq-accordion h3');
			// Click
			faqAcc.on('click', function (event) {
				var $this = $(this);

				$('.faq-accordion').removeClass('active');
				$('.faq-accordion').find('.faq-body').slideUp(400);

				if (!$this.closest('.faq-accordion').find('.faq-body').is(':visible')) {
					$this.closest('.faq-accordion').addClass('active');
					$this.closest('.faq-accordion').find('.faq-body').slideDown(400);
				} else {
					$this.closest('.faq-accordion').removeClass('active');
					$this.closest('.faq-accordion').find('.faq-body').slideUp(400);
				}
				// setTimeout(function () {
				//     $('html, body').animate({
				//         scrollTop: $this.closest('.faq-accordion.active').offset().top - 90
				//     }, 500);
				// }, 700);
				event.preventDefault();
				return false;
			});
		};
		// Document on load.
		$(function () {
			faqsAccordion();
		});
	}());

	if ($.isFunction($.fn.select2)) {
		$('.js-example-basic-single').select2();
	}

	if ($.isFunction($.fn.select2)) {
		$('.js-example-basic-single-multiple').select2({
			closeOnSelect : false,
			placeholder : "Please choose",
			allowHtml: true,
			allowClear: true,
			tags: true,
			containerCssClass : "multi-selector",
			dropdownCssClass: "multi-dropdown-checker"
		});
	}
	function iformat(icon, badge,) {
		var originalOption = icon.element;
		var originalOptionBadge = $(originalOption).data('badge');

		return $('<span><i class="fa ' + $(originalOption).data('icon') + '"></i> ' + icon.text + '<span class="badge">' + originalOptionBadge + '</span></span>');
	}
	$.ajaxSetup({
		'beforeSend': function () {
			$('.loader-blur, .loader').show();
		},
		'complete': function () {
			$('.loader-blur, .loader').hide();
		}
	});

	if (flashstatus == 'SUCCESS') {
		$.toast({
			heading: 'Success',
			text: flashmessage,
			loader: true,
			icon: 'success',
			position: TOAST_POSITION
		});
	}

	if (flashstatus == 'ERROR') {
		$.toast({
			heading: 'Error',
			text: flashmessage,
			loader: true,
			icon: 'error',
			position: TOAST_POSITION
		})
	}

	if (flashstatus == 'INFORMATION') {
		$.toast({
			heading: 'Information',
			text: flashmessage,
			loader: true,
			icon: 'info',
			position: TOAST_POSITION
		})
	}

	if (flashstatus == 'WARNING') {
		$.toast({
			heading: 'Warning',
			text: flashmessage,
			loader: true,
			icon: 'warning',
			position: TOAST_POSITION
		})
	}

	//toggle password

	$(document).on('click', '.toggle-password, #psd', function() {

		$(this).toggleClass("showPsd");

		var input = $("#password");
		input.attr('type') === 'password' ? input.attr('type','text') : input.attr('type','password')
	});
	$(document).on('click', '#psdnew', function() {

		$(this).toggleClass("showPsd");

		var input = $("#newpassword");
		input.attr('type') === 'password' ? input.attr('type','text') : input.attr('type','password')
	});
	$(document).on('click', '#psdconfirm', function() {

		$(this).toggleClass("showPsd");

		var input = $("#confirmpassword");
		input.attr('type') === 'password' ? input.attr('type','text') : input.attr('type','password')
	});
	$(".menu-toggle").click(function (e) {
		e.preventDefault();
		$("#wrapper").toggleClass("toggled");
		$(this).toggleClass('is-active');
		if ($(this).hasClass('is-active')) {
			$('.c-sidebar a').tooltip('enable');
		} else {
			$('.c-sidebar a').tooltip('disable');
		}
	});
	$(".dropdown-menu a").on('click', function(){
		$(this).parents('.btn-group').children(".btn:first-child").html($(this).text()+' <span class="caret"></span>');
	});

	//toggle checkout card price
	$(".chkout-card-header").on("click", function(e){
		$(this).toggleClass("expanded");
		$('.chkout-card-body').slideToggle();
	});

	$(document).on('change', '.country, .countrycity', function(){
        var cityHtml = '<option value="">Select city</option>';
        var timezoneHtml = '<option value="">Select timezone</option>';

        var cities = $('option:selected', this).attr('cities');
        cities = JSON.parse(cities);

		if($('option:selected', this).attr('timezones')){
			var timeZones = $('option:selected', this).attr('timezones');
        	timeZones = JSON.parse(timeZones);
		}

        if(cities != ''){
			if($(this).hasClass('countrycity')) {
				for(var key in cities){
					cityHtml += '<option value="'+cities[key]+'">'+cities[key]+'</option>';
				}
			}else{
				for (const [key, value] of Object.entries(cities)) {
					cityHtml += '<option value="'+key+'">'+value+'</option>';
				}
			}
        }

        if(timeZones != ''){
            $(timeZones).each(function(key, value){
                timezoneHtml += '<option value="'+value+'">'+value+'</option>';
            });
        }

        $('select[name="city"]').html(cityHtml);
        $('select[name="timezone"]').html(timezoneHtml);
	});

	//mobile search
	$('#advanced__search__button').click(function(){
		$('.escort-filter-wrapper').addClass('active');
		$('body').addClass('filter-open').append("<div class='body-overlay'></div>");
    	$('body').find('.body-overlay').fadeIn(100);
	});
	$('.search-main-close').click(function(){
		$('.escort-filter-wrapper').removeClass('active');
		$('body').find('.body-overlay').fadeOut(100);
	});
	//mobile search toggle
	$('#advanced__search__mobile').click(function(){
		$('.advanced__search__area').addClass('active');
	});
	$('.search-close').click(function(){
		$('.advanced__search__area').removeClass('active');
	});
	//desktop search toggle
	$('#advanced__search').click(function(){
		$('.advanced__search__area').toggleClass('active');
		// $('body').addClass('modal-open');
	});
	$('#filter__search').click(function(){
		$('.advanced__search__area').addClass('active');
		$('body').addClass('modal-open');
	});
	$('.advanced__search__cross').click(function(){
		$('.advanced__search__area').removeClass('active');
		// $('body').removeClass('modal-open');
	});

	$('#open__image__modal').click(function(){
		$('#image__modal').addClass('active');
		$('body').addClass('modal-open');
	});

	$('#open__video__modal').click(function(){
		$('#video__modal').addClass('active');
		$('body').addClass('modal-open');
	});
	$('.toggle__search').click(function(){
		$('#search__modal').addClass('active');
		$('body').addClass('modal-open');
	});

	$('.post__modal__btn').click(function(){
		$('#post__modal').addClass('active');
		$('body').addClass('modal-open');
	});
	$('.toggle__nav').on('click', function () {
		$(this).toggleClass('active');
		$('.o-content').toggleClass('active');
		$('body').toggleClass('sideBar-active');
	});

	$('.tab-pane:first').show();
	$('.filter--tab:first').addClass('current');
	$('.filter--tab').click(function(){
		if(!$(this).hasClass('current')) {
			$('.filter--tab.current').removeClass('current');
			$(this).addClass('current');
		} else {
			$(this).removeClass('current');
		}
		$(this).next().toggleClass('active');
		$('.tab-pane').not($(this).next()).removeClass('active');
	});

	//$('.notify-dropdown').hide();
  $('.notify-dp').on('click', function (e) {
    e.stopPropagation(),
      e.preventDefault();
    $('.notify-dropdown').slideToggle('slow');
    $('body').toggleClass('fixed');
    $('.o-navbar').toggleClass('nav-down');
  });
  $('.notf-close-modal').on('click', function (e) {
    e.stopPropagation();
    $('.notify-dropdown').fadeOut('fast');
    $('body').removeClass('fixed');
    $('.o-navbar').removeClass('nav-down');
  });

// Select all links with hashes
$('.static-base a[href*="#"]')
  // Remove links that don't actually link to anything
  .not('[href="#"]')
  .not('[href="#0"]')
  .click(function(event) {
    // On-page links
    if (
      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
      &&
      location.hostname == this.hostname
    ) {
      // Figure out element to scroll to
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      // Does a scroll target exist?
      if (target.length) {
        // Only prevent default if animation is actually gonna happen
        event.preventDefault();
        $('html, body').animate({
          scrollTop: target.offset().top - 80
        }, 1000, function() {
          // Callback after animation
          // Must change focus!
          var $target = $(target);
          $target.focus();
          if ($target.is(":focus")) { // Checking if the target was focused
            return false;
          } else {
            $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
            $target.focus(); // Set focus again
          };
        });
      }
    }
  });

  if($('input[type=hidden][name="postdetailsurl"]').length){
    var clipboard = new Clipboard('.copytoclipboardpost', {
      text: function() {
        return document.querySelector('input[type=hidden][name="postdetailsurl"]').value;
      }
    });

    clipboard.on('success', function(e){
      $.toast({
        heading: "Info",
        text: "Post url copied.",
        loader: true,
        icon: "info",
        position: TOAST_POSITION,
      });

      e.clearSelection();
    });
  }

});

var transparent = $('.navbar--transparent').length;
$(window).on("scroll", function () {
	if(transparent){
		if ($(window).scrollTop() > 0) {
			$(".o-navbar").removeClass("navbar--transparent");
		} else {
			$(".o-navbar").addClass("navbar--transparent");
		}
	}
});

$(".compare_section").click(function() {
	$('html,body').animate({
		scrollTop: $("#compare_block").offset().top},
		'slow');
});


//profile tab height adjust with footer
function calcProfileHeight() {
  setTimeout(() => {
    var leftbarHeight = $('.o-post-inner-lft').outerHeight();
    $('.profile-info-tab').css('min-height', leftbarHeight);
  }, 200);
}

//submit button toggle on focus
  // function chatFocus() {
  //   setTimeout(() => {
  //     $(".write_msg").focus(function(){
  //       console.log('focus selected');
  //       $(this).css('height', '70px');
  //     });
  //     $(".write_msg").focusout(function(){
  //       console.log('focus deselected');
  //       $(this).css('height', 'auto');
  //     });
  //   }, 1000);
  // }

//motification listing modal height adjust
function notifyList() {
  setTimeout(() => {
    var notfheaderHeight = $('.notify-dropdown-header').outerHeight();
    var stickyfooterHeight = $('.o-mobile-footer').outerHeight();
    var bodyHeight = $(window).height();
    var totalHeight = Number(notfheaderHeight) + Number(stickyfooterHeight);
    var listHeight = bodyHeight - totalHeight;
    $('.notf-mobile').height(listHeight);
  }, 500);
}
$(window).on('resize', function () {
  notifyList();
  calcProfileHeight();
});

$(document).ready(function() {
	$(document).on('change', '.blogsitemperpage', function(e){
			console.log(jQuery(this).val());
			jQuery('input[name="perPage"]').val(jQuery(this).val());
			jQuery('form[name="advancesearchform"]').submit();
		});
});

